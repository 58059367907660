.suggestion-item {
    list-style-type: none;
    padding: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
    width: 200px;
    border-radius: 5px; 
  }
  
  .suggestion-item:hover {
    background-color: #e0e0e0;
  }
  