.ReactTags__tags {
  /* display: flex;
  flex-wrap: wrap;
  gap: 5px; */
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  /* background-color: #f8f9fa; */
}

.ReactTags__tag {
  background-color: #464646;
    color: #fff;
    padding: 5px 5px 5px 12px;
    border-radius: 30px;
    font-size: 14px;
    display: inline-block;
    margin: 1px;
}

.ReactTags__remove {
  border: 0;
  background: #a3a3a3;
  border-radius: 50%;
  padding: 0 0 0 4px;
  width: 22px;
  height: 22px;
  margin-left: 5px;
}

.ReactTags__tagInput input {
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
  flex: 1;
}

.ReactTags__tagInput {
  display: flex;
  align-items: center;
  flex: 1;
}

