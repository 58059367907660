/* login.css */
.form_container {
    width: 700px; /* Set a fixed width for the form container */
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 50px 40px 20px 40px;
    /* background-color: #ffffff; */
    background-color: #f5f5f5;
    box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
      0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
      0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    font-family: "Inter", sans-serif;
  }
  
  .logo_container {
    box-sizing: border-box;
    width: 120px; /* Increase the width of the logo container */
    height: 120px; /* Increase the height of the logo container */
    background: linear-gradient(180deg, rgba(248, 248, 248, 0) 50%, #F8F8F888 100%);
    border: 1px solid #F7F7F8;
    filter: drop-shadow(0px 0.5px 0.5px #EFEFEF) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    border-radius: 11px;
  }
  .logo_container img {
    max-width: 180px;
    height: auto;
    display: block; /* This ensures proper rendering and spacing */
    margin: 0 auto; /* Center the image within the container */
}
  
.title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .title {
    margin: 0;
    font-size: 1.5rem; /* Increase the font size of the title */
    font-weight: 700;
    color: #212121;
  }
  
  .subtitle {
    font-size: 0.875rem; /* Increase the font size of the subtitle */
    max-width: 80%;
    text-align: center;
    line-height: 1.2rem;
    color: #8B8E98;
  }
  
  .input_container {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Increase the gap between input containers */
  }
  
  
  .icon {
    width: 24px; /* Increase the size of the icons */
    position: absolute;
    z-index: 99;
    left: 12px;
    bottom: 12px; /* Adjust the position of the icons */
  }
  
  .input_label {
    font-size: 0.875rem; /* Increase the font size of the input label */
    color: #8B8E98;
    font-weight: 600;
  }
  
  .input_field {
    width: 100%;
    height: 48px; /* Increase the height of the input fields */
    padding: 0 0 0 40px;
    border-radius: 7px;
    outline: none;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .input_field:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px #242424;
    background-color: transparent;
  }
  
  .sign-in_btn {
    width: 100%;
    height: 48px;
    border: 0;
    background: #66a3ff; /* Change the default background color */
    border-radius: 7px;
    outline: none;
    font-size: 1rem;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add transition effect */
  }
  
  .sign-in_btn:hover {
    background-color: #3f80ff; /* Change the background color on hover */
  }
  
  
  .separator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    color: #8B8E98;
  }
  
  .separator .line {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e8e8e8;
  }
  
  .note {
    font-size: 0.75rem;
    color: #8B8E98;
    text-decoration: underline;
  }